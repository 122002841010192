import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import LeftSideBar from "../LeftSideBar/LeftSideBar";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthService from "../../utils/auth.service";
import { authActions } from '../../_store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { history } from '../../_helpers';


const AddUsers = () => { 

  const [errMsg, setErrMsg] = useState('')
  let navigate = useNavigate();
  let formatted_date = '';
  

  const dispatch = useDispatch();

  const auth = useSelector(x => x.auth);
  const authError = useSelector(x => x.auth.error);
  // const updateError = useSelector(x => x.auth.updateError);
  const updateUser = useSelector(x => x.auth.updateUser);
 
  if(updateUser) {
    const date = updateUser? new Date(updateUser.subscription_date): '';
    formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
  }

   // get functions to build form with useForm() hook
   const { register, handleSubmit, watch, formState } = useForm();
   const { errors, isSubmitting } = formState;

    // Email Pattern Validation
    const handleEmail = (value) => { 
         if (!/\S+@\S+\.\S+/.test(value)) {
          return "Email Invalid";
      }
    }
   
   const onSubmit = ({ product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName  }) => {
        if (updateUser) 
            {return dispatch(authActions.updateUser({ product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName }));}     
        else 
            {return dispatch(authActions.adduser({ product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName })); }
    }
  //  console.log("auth", auth);

  const cancelUpdate = () => {
    return dispatch(authActions.cancelUpdate());
  }
  
   if (updateUser) {
    console.log("updateUserData", updateUser);    
    }
   
    return (
      <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="card">
                <div className="card-body">
                        {authError &&
                        <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                        }
                        {/* {updateError &&
                        <div className="alert alert-danger mt-3 mb-0">{updateError.message}</div>
                        } */}
                <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                          <div className="col-12">
                            {!updateUser && <h1>Add New User</h1>}
                            {updateUser && <h1>Update User</h1>}
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput1">Product ID</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Enter Product Id"
                            defaultValue ={updateUser ? updateUser.product_id : ''}
                            {...register("product_id", { required: true })}/>
                            {errors.product_id && <span className="error">Product ID required</span>} 
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput2">Company Name</label>
                            <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="Enter Company Name"
                            defaultValue ={updateUser ? updateUser.company_name : ''}
                            {...register("company_name", { required: true, minLength: 2, pattern: /^[A-Za-z\s]+$/i})}/>
                            {errors.company_name && errors.company_name.type ==='pattern' &&<span className="error">Invalid Company Name</span>}
                            {errors.company_name && errors.company_name.type ==='required' &&<span className="error">Company Name required</span>}
                            {errors.company_name && errors.company_name.type ==='minLength' &&<span className="error">Minimum 2 Characters required</span>}
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput3">First Name</label>
                            <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="Enter First Name"
                            defaultValue ={updateUser ? updateUser.firstname : ''} 
                            {...register("firstName", { required: true, minLength: 2, pattern: /^[A-Za-z]+$/i })}/>
                            {errors.firstName && errors.firstName.type ==='required' && <span className="error">First Name required</span>} 
                            {errors.firstName && errors.firstName.type ==='minLength' && <span className="error">Minimum 2 Characters required</span>} 
                            {errors.firstName && errors.firstName.type ==='pattern' && <span className="error">Invalid First Name</span>} 
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput3">Last Name</label>
                            <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="Enter Last Name"
                            defaultValue ={updateUser ? updateUser.lastname : ''} 
                            {...register("lastName", { required: true, pattern: /^[A-Za-z]+$/i  })}/>
                            {errors.lastName && errors.lastName.type ==='required' &&<span className="error">Customer Name required</span>}
                            {errors.lastName && errors.lastName.type ==='pattern' && <span className="error">Invalid Last Name</span>} 
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput3">Email</label>
                            <input type="email" className="form-control" id="exampleFormControlInput3" placeholder="Enter Email"
                            defaultValue ={updateUser ? updateUser.email : ''} 
                            {...register("email", { required: true, validate: handleEmail })}/>
                            {errors.email && <span className="error">{errors.email.message}</span>}
                            {errors.email && errors.email.type === 'required' && <span className="error">Email is required</span>}
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput3">Password</label>
                            <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="Enter Password"
                            defaultValue ={updateUser ? updateUser.password : ''} 
                            {...register("password", { required: true, minLength:8 })}/>
                            {errors.password && errors.password.type === 'required' && <span className="error">Password is required</span>}
                            {errors.password && errors.password.type ==='minLength' && <span className="error">Minimum 8 Characters required</span>} 
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput4">Date of Subscription</label>
                            <input type="date" className="form-control" id="exampleFormControlInput4" placeholder="Enter Subscription date" 
                            defaultValue ={updateUser ? formatted_date : ''} 
                            {...register("subscription_date", { required: true })}/>
                            {errors.subscription_date && <span className="error">Subscription Date required</span>}
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                              <label htmlFor="exampleFormControlInput5">User Type</label>
                              <select className="input-field" {...register("user_type", { required: false })}
                              defaultValue={updateUser ? updateUser.user_type : 'company'}>
                                <option value="company">Company</option>
                                {/* <option value="staff">Staff</option> */}
                              </select>
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput5">District</label>
                            <input type="text" className="form-control" id="exampleFormControlInput5" placeholder="Enter District"
                            defaultValue ={updateUser ? updateUser.district : ''}
                            {...register("district", { required: true, minLength:3, pattern: /^[A-Za-z]+$/i })}/>
                            {errors.district &&  errors.district.type === 'required' && <span className="error">District required</span>}
                            {errors.district &&  errors.district.type === 'pattern' && <span className="error">Invalid District name</span>}
                            {errors.district && errors.district.type ==='minLength' && <span className="error">Minimum 3 Characters required</span>} 
                          </div>

                          <div className="col-12 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="exampleFormControlInput6">State</label>
                            <input type="text" className="form-control" id="exampleFormControlInput6" placeholder="Enter State" 
                            defaultValue ={updateUser ? updateUser.state : ''}
                            {...register("state", { required: true, minLength:3, pattern: /^[A-Za-z]+$/i  })}/>
                            {errors.state && errors.state.type === 'required' && <span className="error">State required</span>}
                            {errors.state && errors.state.type === 'pattern' && <span className="error">Invalid State name</span>}
                            {errors.state && errors.state.type === 'minLength' && <span className="error">Minimum 3 Characters required</span>}
                          </div>

                          <div className="mb-3 p-2">
                            <button type="submit" className="btn btn-primary mb-2"> {updateUser ? 'Update User' : 'Add User'} </button>
                          </div>
                          {
                            updateUser && 
                            <div className="mb-3 p-2">
                            <button type="button" onClick={cancelUpdate} className="btn btn-danger mb-2"> Cancel </button>
                            </div>
                            }
                          
                      </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
    )
  
}
const DeleteUsers = () => { 

  const [errMsg, setErrMsg] = useState('')
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const authUser = useSelector(x => x.auth.user);
  const DeleteAuthError = useSelector(x => x.auth.deleteError);

   // get functions to build form with useForm() hook
   const { register, handleSubmit, watch, formState } = useForm();
   const { errors, isSubmitting } = formState;
 
   const onSubmit = ({ product_id }) => {   
    if (window.confirm('Are you sure you want to Delete?')) {      
      console.log("deleted", product_id);
       return dispatch(authActions.deleteUser({ product_id }));
    }
   }
  
   console.log(watch("authUser"));

    return (
      <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="card">
                <div className="card-body">

                        {DeleteAuthError &&
                        <div className="alert alert-danger mt-3 mb-0">{DeleteAuthError.message}</div>
                        }

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                          <h1>Delete User</h1>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput7">Product Id</label>
                          <input type="number" className="form-control" id="exampleFormControlInput7" placeholder="Enter Product Id" 
                          {...register("product_id", { required: true })}/>
                          {errors.product_id && <span className="error">Product ID required</span>} 
                        </div>
                        <div className="col-12  mb-3">
                          <button type="submit" className="btn btn-danger mb-2">Delete User</button>
                        </div>
                      </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
    )
}



const ManageUsers = () => { 

    return (
      <div className="main-panel">
        <div className="content-wrapper">
          {/* <!-- Page Title Header Starts--> */}
          <div className="row page-title-header">
            <div className="col-12">
              <div className="page-header">
                <h4 className="page-title">Control and Monitor Dashboard</h4>
                <div className="quick-link-wrapper w-100 d-md-flex flex-md-wrap">
                  {/* <!-- <ul className="quick-links">
                <li><a href="#">option 1</a></li>
                <li><a href="#">Own analysis</a></li>
                <li><a href="#"> data</a></li>
              </ul> --> */}
                  <ul className="quick-links ml-auto">
                    <li>
                      <a href="#">Settings</a>
                    </li>
                    <li>
                      <a href="#">Option 1</a>
                    </li>
                    <li>
                      <a href="#">option 2</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Page Title Header Ends--> */}

          <AddUsers/>
          <DeleteUsers/>

        </div>

        <footer className="footer">
          <div className="container-fluid clearfix">
            <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
              AquaBox Control and Monitor System
            </span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
              {" "}
              ©{" "}
              <a href="" target="_blank">
                Ebhoom Solutions LLP
              </a>{" "}
              2022
            </span>
          </div>
        </footer>
      </div>
    )
}


export default ManageUsers;