import React, { useState } from "react";

import { authActions } from '../../_store';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import './index.css'
import { useOutletContext } from "react-router-dom";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Link } from 'react-router-dom';
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { history, fetchWrapper } from '../../_helpers';
import { DateRangePicker } from 'rsuite';
import { useForm, Controller } from "react-hook-form";
import format from 'date-fns/format';

const UsersLog = () => {

  const [errMsg, setErrMsg] = useState('')
  let navigate = useNavigate();

  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  

  const [soketObj] = useOutletContext();
  var authUser = useSelector(x => x.auth.user);
  const authError = useSelector(x => x.auth.error);
  const auth = useSelector(x => x.auth);
  const userList = useSelector(x => x.auth.userData);

  const { register, handleSubmit, watch, formState, control } = useForm();

  const { errors, isSubmitting } = formState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.userlist())
  }, [dispatch]);


  const onEdit = ({ product_id }) => {
    dispatch(authActions.getUserByID({ product_id }));
  }
  const onSelect = (d) =>{
    console.log(d);
  };
  const onLogin = ({ product_id }) => {
    if (soketObj) {
      soketObj.disconnect();
    }
    dispatch(authActions.switchUser(product_id));
    history.navigate('/dashboard');
  }

  const [product_id, setProductID] = React.useState();
  const [date_range, setDateRange] = React.useState();

  const onSubmit = ({ product_id, date_range }) => {
      console.log("deleted", product_id, date_range[0], date_range[1]);
      window.open(process.env.REACT_APP_API_URL+'/download-file','_blank');
      //return dispatch(authActions.downloadFile({ product_id: product_id, from: date_range[0], to: date_range[1] }));
  }

  console.log("userList", userList);
  // console.log("auth",auth.userData);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {/* <!-- Page Title Header Starts--> */}
        <div className="row page-title-header">
          <div className="col-12">
            <div className="page-header">
              <h4 className="page-title">Control and Monitor Dashboard</h4>
              <div className="quick-link-wrapper w-100 d-md-flex flex-md-wrap">
                {/* <!-- <ul className="quick-links">
    <li><a href="#">option 1</a></li>
    <li><a href="#">Own analysis</a></li>
    <li><a href="#"> data</a></li>
  </ul> --> */}
                <ul className="quick-links ml-auto">
                  <li>
                    <a href="#">Settings</a>
                  </li>
                  <li>
                    <a href="#">Option 1</a>
                  </li>
                  <li>
                    <a href="#">option 2</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Page Title Header Ends--> */}
        <div className="row">
          <div className="col-12 col-md-6 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h1>Active Users List</h1>
                  </div>
                  <div className="col-12  mb-3">
                    <ul className="list-group">
                      {userList && userList.map((user, i) =>
                        <li key={user.product_id} className="list-group-item">{i + 1}. {user.product_id}
                          <div className="FloatRight">
                            <a onClick={() => onEdit({ product_id: user.product_id })}>View</a>
                            <a onClick={() => onLogin({ product_id: user.product_id })}>Login</a>
                          </div>

                        </li>
                      )}
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h1>Non Active Users List</h1>
                  </div>

                  <div className="col-12  mb-3">
                    <ul className="list-group">
                      <li className="list-group-item">1.Product ID</li>
                      <li className="list-group-item">2.Product ID</li>
                      <li className="list-group-item">3.Product ID</li>
                      <li className="list-group-item">4.1233242</li>
                      <li className="list-group-item">5.1321312</li>
                    </ul> </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <form action={process.env.REACT_APP_API_URL+'/download-file'} target="_blank" method="post">
                  <label htmlFor="exampleFormControlInput5">User</label>
                  <select id="pdid" className="input-field" {...register("product_id", { required: true })}>
                    {userList && userList.map((user, i) =>
                      <option key={user.product_id} value={user.product_id}>{user.product_id} - {user.company_name}</option>
                    )}
                  </select>
                  <Controller
                    control={control}
                    name="date_range"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DateRangePicker
                        format='yyyy-MM-dd'
                        onChange={onChange}
                        onSelect={(date) => {
                          //console.dir(date.toISOString().substring(0, 10));
                        }}
                        onOk={([from, to]) => {
                          console.dir(from.toISOString().substring(0, 10));
                          console.dir(to.toISOString().substring(0, 10));
                          setFrom(from.toISOString().substring(0, 10));
                          setTo(to.toISOString().substring(0, 10));
                        }}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  <input type="hidden" name="auth" value={authUser.session_tocken} />
                  <input type="hidden" name="from" value={from} />
                  <input type="hidden" name="to" value={to} />
                  <button type="submit" className="btn btn-primary mb-2"> Download </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid clearfix">
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
            AquaBox Control and Monitor System
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            {" "}
            ©{" "}
            <a href="" target="_blank">
              Ebhoom Solutions LLP
            </a>{" "}
            2022
          </span>
        </div>
      </footer>
    </div>
  );

}

export default UsersLog;