import { useState } from "react";
import { Link } from 'react-router-dom'
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthService from "../../utils/auth.service";
import { authActions } from '../../_store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { history } from '../../_helpers';


const SignUp = () => {


  const [errMsg, setErrMsg] = useState('')
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/signup`;
    navigate(path);
  }

  const dispatch = useDispatch();
  const authUser = useSelector(x => x.auth.user);

  const authError = useSelector(x => x.auth.error);

  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate('/');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // get functions to build form with useForm() hook
  const { register, handleSubmit, watch, formState } = useForm();
  const { errors, isSubmitting } = formState;

  // Password Match validation
  const password = watch("password");
  const handleConfirmPassword = (value) => {
    if (value == null) {
      return "Confirm Password Required";
    }
    else if (value !== password) {
      return "Passwords don't match";
    }
  };

  // Email Pattern Validation
  const handleEmail = (value) => {
     if (register.email == 'undefined') {
      return "Email Required";
  }
    else if (!/\S+@\S+\.\S+/.test(value)) {
      return "Email Invalid";
  }
}

  const onSubmit = async ({ product_id, email, password, confirm_password, user_type, firstName, lastName }) => {
    return dispatch(authActions.signup({ product_id, email, password, confirm_password, user_type, firstName, lastName }));
  }

  // console.log(watch("authUser"));
  
    return (
      <>
        <div className="pt-4 pb-4"></div>
        <div className="container">
          <div className="row ">
             
                  <div className="col-12 col-lg-3"></div>
                  <div className="col-12 col-lg-6 ">

                  {authError &&
                      <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                    }
                    <form  onSubmit={handleSubmit(onSubmit)}>
                        <p className="signup-head">Get yourself an with Product ID</p>
                          <div className="mb-4">
                            <input
                              className="input-field"
                              type="number"
                              placeholder="Product ID"
                              name="product_id"
                              {...register("product_id", { required: true })}
                            />
                            {errors.product_id && <span className="error">Product ID required</span>} 
                          </div>
                          <div className="mb-4">
                            <input
                              className="input-field"
                              type="text"
                              placeholder="First Name"
                              name="firstName"
                              {...register("firstName", { required: true, minLength: 2 })}
                            />
                            {errors.firstName && errors.firstName.type ==='minLength' && <span className="error">Minimum 2 Characters required</span>} 
                            {errors.firstName && errors.firstName.type ==='required' && <span className="error">First Name required</span>} 
                          </div>
                          <div className="mb-4">
                            <input
                              className="input-field"
                              type="text"
                              placeholder="Last Name"
                              name="lastName"
                              {...register("lastName", { required: true })}
                            />
                            {errors.lastName && <span className="error">Last Name required</span>} 
                          </div>
                          <div className="mb-4">
                            <input
                              className="input-field"
                              type="text"
                              placeholder="Email"
                              name="email"
                              {...register("email", { required: true, validate: handleEmail })}
                            />
                            {errors.email && errors.email.type === 'required' && <span className="error">Email is required</span>}
                            {errors.email && <span className="error">{errors.email.message}</span>}
                          </div>
                          <div className="mb-4">
                            <input
                              className="input-field"
                              type="password"
                              placeholder="Password"
                              name="password"
                              {...register("password", { required: true, minLength:8 })}
                            />
                            {errors.password && errors.password.type ==='minLength' && <span className="error">Minimum 8 Characters required</span>} 
                            {errors.password && <span className="error">Password is required</span>}
                          </div>
                          <div className="mb-4">
                            <input
                              className="input-field"
                              type="password"
                              placeholder="Confirm password"
                              name="confirm_password"
                              {...register("confirm_password", { required: true, validate: handleConfirmPassword })}
                            />
                            {errors.confirm_password && errors.confirm_password.type === 'required' && <span className="error">Confirm Password is required</span>}
                            {errors.confirm_password && <span className="error">{errors.confirm_password.message}</span>}
                          </div>

                          <div className="mb-4">
                            <select className="input-field" {...register("user_type", { required: true })}>
                              <option value="company">Company</option>
                              <option value="staff">Staff</option>
                            </select>
                          </div>
                          <div className="mb-4">
                            <input type="checkbox" id="terms" name="terms" value="terms"
                              {...register("terms", { required: true })} />
                            <label className="terms-text">
                              {" "}
                              Agree to our <a href="">terms and conditions</a>
                            </label>
                            {errors.terms && <span className="error">Please Accept terms and conditions </span>}
                          </div>
                        <button className="signin-button mb-5" type="submit">Continue</button>                    
                        {/* <Link className="link" to="/signup-confirmation"> <button className="signin-button mb-5">Continue</button></Link> */}
      
                      <div className="text-center">
                      <p>Already have an account?</p>
                        <Link className="link" to="/"><button className='signup-button' type="button">Sign In</button></Link>
                      </div>
                   </form>
                  </div>
                <div className="col-12 col-lg-3"></div>
              
          </div>
        </div>
      </>
    );
  }

export default SignUp;
