import React, { useState, useEffect } from 'react';
import Attendence from "../Attendance/Attendence";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useOutletContext } from "react-router-dom";

import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Link } from 'react-router-dom';

const LeftSideBar = () => {

  //const [active] = useOutletContext();
  var pathname = useLocation().pathname;
  const authUser = useSelector(x => x.auth.user);
  const menu = useSelector(x => x.auth.menu);
  
  var className = pathname.replace('/','')?pathname.replace('/',''):'dashboard';

  return (
    <nav className={`sidebar sidebar-offcanvas ${menu? "active" : ""}`} id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a href="#" className="nav-link">
            <div className="text-wrapper">
              <p className="profile-name">Ebhoom Solutions</p>
              <p className="designation">AquaBox Model M</p>
            </div>
          </a>
        </li>
        <li className="nav-item nav-category">Main Menu
        </li>
        {
          (authUser.product_id === 0 || authUser.user_type === 'admin' )&&
          <>
            <li className={`nav-item ${className == 'manage-users' ? 'active' : ''}`}>
              <Link className="nav-link" to="/manage-users">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Manage Users</span>
              </Link>
            </li>
            <li className={`nav-item ${className == 'users-log' ? 'active' : ''}`}>
              <Link className="nav-link" to="/users-log">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Users Log</span>
              </Link>
            </li>
          </>
        }

        {
          authUser.product_id > 0 &&
          <>
            <li className={`nav-item ${className == 'dashboard' ? 'active' : ''}`}>
              <Link className="nav-link" to="/dashboard">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
            <li className={`nav-item ${className == 'attendance-report' ? 'active' : ''}`}>
              <Link className="nav-link" to="/attendance-report">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Attendance Register</span>
              </Link>
            </li>
            <li className={`nav-item ${className == 'account' ? 'active' : ''}`}>
              <Link className="nav-link" to="/account">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Account</span>
              </Link>
            </li>
            {/* <li className={`nav-item ${className == 'report' ? 'active' : ''}`}>
              <Link className="nav-link" to="/">
                <i className="menu-icon typcn typcn-document-text"></i>
                <span className="menu-title">Report</span>
              </Link>
            </li> */}
          </>
        }
      </ul>
    </nav>
  );
};
export default LeftSideBar;