class AuthService {
  user = false;
  getUser(){
    return this.user;
  }
  setUser(user){
    this.user = user;
  }
}

export default new AuthService();
