import { Component } from "react";
import {Link} from 'react-router-dom'
import "./index.css";

import { authActions } from '../../_store';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const ResetPasswordOtp = () => {

  const { register, handleSubmit, setValue, formState } = useForm();
  const { errors, isSubmitting } = formState;

  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  const otpVerifyError = useSelector(x => x.auth.otpVerifyError);
  const verifyEMail = useSelector(x => x.auth.email);
  const auth = useSelector(x => x.auth);
  
  if (verifyEMail) {
    console.log("verifyEMail-OTP: -- ",verifyEMail.email);
  }

  useEffect(() => {
    if (verifyEMail) {
      setValue('email', verifyEMail.email);
    } else {
      navigate("/");
    }
  }, []);

  const onSubmit = ({ email, otp }) => {
    return dispatch(authActions.forgotVerifyOTP({ email, otp }));
  }


    return (
      <>
        <div className="pt-4 pb-4"></div>
        <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12 col-lg-3"></div>
                <div className="col-12 col-lg-6">
                  <p className="signup-head">Enter 6 digit OTP</p>
                  <input
                    className="input-field-confirmation mb-4"
                    type="password"
                    placeholder="Enter OTP"
                    {...register("otp", { required: true })} />
                    {errors.otp && <span className="error">Please Enter OTP!</span>}
                
                   <button className="signin-button mb-5" type="submit">Continue</button>

                <div className="text-center">
                <Link className="link" to="/"> <button className='home-button'>Home</button></Link>
                </div>
                </div>
                <div className="col-12 col-lg-3"></div>
              </div>
          </form>
        </div>
      </>
    );
  
}

export default ResetPasswordOtp;
