import { createSlice } from '@reduxjs/toolkit'
const initialState = { props: {}, socket: null }

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setProps(state, param) {
      const { payload } = param;
      // merge/update state partialy with data received
      state.props = {...state.props, ...payload}; 
    },
    setSoket(state, param) {
      //console.log(state,param)
      const { payload } = param;
      state.soket = payload;
    },
    disconnectSoket(state) {
      if (state.soket.connected) {
        state.soket.disconnect();
      }
    }
  },
})

export const dashActions = { ...dashboardSlice.actions };
export const dashReducer = dashboardSlice.reducer;

//export default dashboardSlice.reducer