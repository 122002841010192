import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '../_helpers';



// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        menu: false,
        user: JSON.parse(localStorage.getItem('user')),
        product_id: 0,
        userList: [],
        error: null
    }
}

function createReducers() {
    return {
        logout,
        cancelUpdate,
        switchUser,
        setMenu
    };

    function setMenu(state) {
        // if(state.menu === 'active'){
        //     state.menu = '';
        // }else{
        //     state.menu = 'active';
        // }
        state.menu = !state.menu
    }
    function logout(state) {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/');
    }
    function cancelUpdate(state) {
        state.updateUser = null;
        history.navigate('/users-log');
    }
    function switchUser(state, param) {
        const { payload } = param;
        state.product_id = payload;
        state.user['product_id'] = payload;
    }
}

function createExtraActions() {

    return {
        login: login(),
        signup: signup(),
        verify: verify(),
        adduser: adduser(),
        userlist: userlist(),
        deleteUser: deleteUser(),
        downloadFile: downloadFile(),
        getUserByID: getUserByID(),
        updateUser: updateUser(),
        forgotVerify: forgotVerify(),
        forgotVerifyOTP: forgotVerifyOTP(),
        resetPassword: resetPassword()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/login`, { email, password })
        );
    }
    function signup() {
        return createAsyncThunk(
            `${name}/signup`,
            async ({ product_id, email, password, confirm_password, user_type, firstName, lastName }) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/signup`, { product_id, email, password, confirm_password, user_type, firstName, lastName })
        );
    }
    function adduser() {
        return createAsyncThunk(
            `${name}/adduser`,
            async ({ product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName }) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/adduser`, { product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName })
        );
    }
    function updateUser() {
        return createAsyncThunk(
            `${name}/updateUser`,
            async ({ product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName }) => await fetchWrapper.put(`${process.env.REACT_APP_API_URL}/updateUser`, { product_id, company_name, subscription_date, district, state, email, password, user_type, firstName, lastName })
        );
    }
    function deleteUser() {
        return createAsyncThunk(
            `${name}/delete-user`,
            async ({ product_id }) => await fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/delete-user`, { product_id })
        );
    }

    function downloadFile() {
        return createAsyncThunk(
            `${name}/download-file`,
            async ({ product_id, from, to }) => {
                console.log(product_id, from, to);
                fetchWrapper.post(`${process.env.REACT_APP_API_URL}/download-file`, { product_id, from, to })
                .then(()=>{
                    console.log('===donwloadf====');
                });
            }
        );
    }

    function getUserByID() {
        return createAsyncThunk(
            `${name}/getUserByID`,
            async ({ product_id }) => {
                const res = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/getUserByID`, { product_id });
                return res.data;
            }
        );
    }


    function userlist() {
        return createAsyncThunk(
            `${name}/userlist`,
            async () => {
                const res = await fetchWrapper.get(`${process.env.REACT_APP_API_URL}/userlist`);
                return res.data;
            }
        );
    }

    function verify() {
        return createAsyncThunk(
            `${name}/verify`,
            async ({ email, verification }) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/verify`, { email, verification })
        );
    }
    function forgotVerify() {
        return createAsyncThunk(
            `${name}/forgotVerify`,
            async ({ email }) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/forgotVerify`, { email })
        );
    }
    function forgotVerifyOTP() {
        return createAsyncThunk(
            `${name}/forgotVerifyOTP`,
            async ({ email, otp }) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/forgotVerifyOTP`, { email, otp })
        );
    }
    function resetPassword() {
        return createAsyncThunk(
            `${name}/reset-password`,
            async ({ email, password }) => await fetchWrapper.put(`${process.env.REACT_APP_API_URL}/reset-password`, { email, password })
        );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...signup(),
        ...verify(),
        ...adduser(),
        ...userlist(),
        ...deleteUser(),
        ...downloadFile(),
        ...getUserByID(),
        ...updateUser(),
        ...forgotVerify(),
        ...forgotVerifyOTP(),
        ...resetPassword()
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                state.product_id = user.product_id;

                // get return url from location state or default to home page
                //const { from } = (history.location && history.location.state)?history.location.state : { from: { pathname: '/dashboard' } };
                if (user && user.user_type === 'admin') {
                    history.navigate({ pathname: '/users-log' });
                } else {
                    history.navigate({ pathname: '/dashboard' });
                }

            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function signup() {
        var { pending, fulfilled, rejected } = extraActions.signup;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const res = action.payload;
                state.email = res;

                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/signup-confirmation' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function adduser() {
        var { pending, fulfilled, rejected } = extraActions.adduser;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                // const res = action.payload;

                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/users-log' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function updateUser() {
        var { pending, fulfilled, rejected } = extraActions.updateUser;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                // const res = action.payload;
                state.updateUser = null;
                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/users-log' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function downloadFile() {
        var { pending, fulfilled, rejected } = extraActions.downloadFile;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                 const res = action.payload;

                // get return url from location state or default to home page
            },
            [rejected]: (state, action) => {
                state.downloadFileError = action.error;
            }
        };
    }
    function deleteUser() {
        var { pending, fulfilled, rejected } = extraActions.deleteUser;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                // const res = action.payload;

                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/users-log' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.deleteError = action.error;
            }
        };
    }

    function getUserByID() {
        var { pending, fulfilled, rejected } = extraActions.getUserByID;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const res = action.payload;
                state.updateUser = res;

                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/manage-users' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                console.log(action.error);
            }
        };
    }

    function userlist() {
        var { pending, fulfilled, rejected } = extraActions.userlist;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const res = action.payload;
                state.userData = res;

            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function verify() {
        var { pending, fulfilled, rejected } = extraActions.verify;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                // const user = action.payload;


                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function forgotVerify() {
        var { pending, fulfilled, rejected } = extraActions.forgotVerify;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const res = action.payload;
                state.email = res;

                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/reset-password-otp' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.forgotVerifyError = action.error;
            }
        };
    }
    function forgotVerifyOTP() {
        var { pending, fulfilled, rejected } = extraActions.forgotVerifyOTP;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const res = action.payload;


                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/reset-password' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.forgotVerifyOTPError = action.error;
            }
        };
    }
    function resetPassword() {
        var { pending, fulfilled, rejected } = extraActions.resetPassword;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const res = action.payload;


                // get return url from location state or default to home page
                const { from } = (history.location && history.location.state) ? history.location.state : { from: { pathname: '/' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
