import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Account from "./Components/Account/Account";
import Dashboard from "./Components/Dashboard/Dashboard";
import Attendence from "./Components/Attendance/Attendence";
import ManageUsers from "./Components/ManageUsers/ManageUsers";
import UsersLog from "./Components/UsersLog/UsersLog";
import SignIn from "./Components/SignIn/SignIn";
import SignUp from "./Components/SignUp/SignUp";
import SignUpConfirmation from "./Components/SignUp/SignUpConfirmation";
import ResetPasswordOtp from "./Components/ResetPassword/ResetPasswordOtp";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import ResetPasswordEmail from "./Components/ResetPassword/ResetPasswordEmail";
import Faq from "./Components/Faq/Faq";
import Terms from "./Components/Terms/Terms";
import PublicLayout from "./Components/PublicLayout/PublicLayout";
import PrivateLayout from "./Components/PrivateLayout/PrivateLayout";

import { history } from './_helpers';
import "./App.css"
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, dashActions, authReducer } from './_store';

function App() {

  history.navigate = useNavigate();
  history.location = useLocation();
  const menu = useSelector(x => x.auth.menu);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('menu',menu);
    if(menu){
      dispatch(authActions.setMenu());
    }
  }, [history.location])
  
  return (
    <>

      <Routes>
        <Route path="/" element={<PublicLayout />} >
          <Route exact path="" element={<SignIn />} />
          <Route exact path="signup" element={<SignUp />} />
          <Route exact path="signup-confirmation" element={<SignUpConfirmation />} />
          <Route exact path="reset-password-otp" element={<ResetPasswordOtp />} />
          <Route exact path="reset-password" element={<ResetPassword />} />
          <Route exact path="reset-password-email" element={<ResetPasswordEmail />} />
          <Route exact path="faq" element={<Faq />} />
          <Route exact path="terms" element={<Terms />} />
        </Route>

        <Route exact path="/" element={<PrivateLayout />} >
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/attendance-report" element={<Attendence />} />
          <Route exact path="/manage-users" element={<ManageUsers />} />
          <Route exact path="/users-log" element={<UsersLog />} />
        </Route>


      </Routes>
    </>
  );
}

export default App;
