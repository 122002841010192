import { Component, useState } from "react";
import { Link } from 'react-router-dom'
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthService from "../../utils/auth.service";
import { authActions } from '../../_store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { history } from '../../_helpers';

const SignIn = () => {

  const [errMsg, setErrMsg] = useState('')
  
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/dashboard`;
    navigate(path);
  }

  const dispatch = useDispatch();
  const authUser = useSelector(x => x.auth.user);

  const authError = useSelector(x => x.auth.error);

  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate('/');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // get functions to build form with useForm() hook
  const { register, handleSubmit, watch, formState } = useForm();
  const { errors, isSubmitting } = formState;

  const onSubmit = ({ email, password }) => {
    return dispatch(authActions.login({ email, password }));
  }

  console.log(watch("authUser"));

  return (
    <>
      <div className="pt-4 pb-4"></div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 blue-box">
            <h1 className="blue-box-text">
              Monitor and Control <br /> your  <br /> Water Treatment Plant
            </h1>
          </div>
          <div className="col-12 col-lg-6 padd pt-4">

            {authError &&
              <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="signin-text mb-5">Sign In</p>
              <span className="error">{errMsg}</span>
              <div className="mb-4">
                <input className="input-field" type="text" placeholder="Email" {...register("email", { required: true })} />
                {errors.email && <span className="error">This field is required</span>}
              </div>
              <div className="mb-4">
                <input className="input-field" type="password" placeholder="Password"  {...register("password", { required: true })} />
                {errors.email && <span className="error">This field is required</span>}
              </div>
              <Link className="link" to="/reset-password-email"> <p className="forgot">Forgot password?</p></Link>
              <select className="input-field mb-4">
                <option value="company">Company</option>
                <option value="staff">Staff</option>
              </select>
              <button type="submit" className="signin-button" >Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
