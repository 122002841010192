import { Component } from "react";
import {Link} from 'react-router-dom'
import "./index.css";

import { authActions } from '../../_store';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';

const ResetPasswordEmail = () => {
  // forgotVerify

  const { register, handleSubmit, watch, formState } = useForm();
  const { errors, isSubmitting } = formState;

  const dispatch = useDispatch();
  const EmailVerifyError = useSelector(x => x.auth.EmailVerifyError);

    // Email Pattern Validation
    const handleEmail = (value) => {
      if (register.email == 'undefined') {
       return "Email Required";
   }
     else if (!/\S+@\S+\.\S+/.test(value)) {
       return "Email Invalid";
   }
 }

  const onSubmit = ({ email }) => {
    return dispatch(authActions.forgotVerify({ email }));
  }

    return (
      <>
        <div className="pt-4 pb-4"></div>
        <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12 col-lg-3"></div>
                <div className="col-12 col-lg-6">
                  <p className="signup-head">Enter Email to receive OTP</p>
                  <input
                    className="input-field mb-4"
                    type="text"
                    placeholder="Email"
                    {...register("email", { required: true, pattern:/\S+@\S+\.\S+/ })} />
                    {errors.email && errors.email.type === 'required' && <span className="error">Please Enter your Email ID!</span>}
                    {errors.email && errors.email.type === 'pattern' && <span className="error">Please Enter valid Email ID!</span>}
                {/* <Link className="link" to="/reset-password-otp"><button className="signin-button mb-5">Send OTP</button></Link> */}
                <button className="signin-button mb-5" type="submit">Send OTP</button> 

                <div className="text-center">
                <Link className="link" to="/"><button className='home-button'>Home</button></Link>
                </div>
                </div>
                <div className="col-12 col-lg-3"></div>
              </div>
        </form>
        </div>
      </>
    );
  
}

export default ResetPasswordEmail;
