import { Component } from "react";
import {Link} from 'react-router-dom'
import "./index.css";

import { authActions } from '../../_store';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const ResetPassword = () => { 

  const { register, handleSubmit, setValue, watch, formState } = useForm();
  const { errors, isSubmitting } = formState;

  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  const otpVerifyError = useSelector(x => x.auth.otpVerifyError);
  const verifyEMail = useSelector(x => x.auth.email);

    useEffect(() => {
      if (verifyEMail) {
        setValue('email', verifyEMail.email);
      } else {
        navigate("/");
      }
    }, []);
      // Password Match validation
      const password = watch("password");
      const handleConfirmPassword = (value) => {
        if (value == null) {
          return "Confirm Password Required";
        }
        else if (value !== password) {
          return "Passwords don't match";
        }
      };

    const onSubmit = ({ email, password }) => {
      return dispatch(authActions.resetPassword({ email, password }));
    }
  


    return (
      <>
        <div className="pt-4 pb-4"></div>
        <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          
            <div className="row">
                <div className="col-12 col-lg-3"></div>
                <div className="col-12 col-lg-6">
                  <p className="signup-head">Reset Password</p>
                  <input
                    className="input-field mb-4"
                    type="password"
                    placeholder="Password"
                    name="password"
                    {...register("password", { required: true, minLength:8 })}
                  />
                  {errors.password && errors.password.type ==='minLength' && <span className="error">Minimum 8 Characters required</span>} 
                  {errors.password && errors.password.type ==='required' && <span className="error">Password is required</span>}
                  
                  <input
                    className="input-field mb-4"
                    type="password"
                    placeholder="Confirm password"
                    name="confirm_password"
                    {...register("confirm_password", { required: true, validate: handleConfirmPassword })}
                  />
                  {errors.confirm_password && errors.confirm_password.type === 'required' && <span className="error">Confirm Password is required</span>}
                  {errors.confirm_password && <span className="error">{errors.confirm_password.message}</span>}
                
                  
                <button className="signin-button mb-5" type="submit">Reset</button>

                <div className="text-center">
                <Link className="link" to="/"> <button className='home-button'>Cancel</button> </Link>
                </div>
                </div>
                <div className="col-12 col-lg-3"></div>
              </div>
              
          </form>
        </div>
      </>
    );
  
}

export default ResetPassword;
