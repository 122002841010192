import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { dashReducer } from './dashboard.slice';

export * from './auth.slice';
export * from './users.slice';
export * from './dashboard.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        dashboard: dashReducer
    },
});